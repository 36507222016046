import React, { useEffect, useState } from 'react';

export default function Header({ ifBuyer, setIfBuyer }) {
    const [isScrolled, setIsScrolled] = useState(false);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleClick() {
        setIfBuyer(!ifBuyer);
        
        window.scrollTo({
            top: 0,
        });
    }

    return (
        <>
            <header className={`fixed top-0 w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-white drop-shadow-md py-4 md:py-6 px-4 md:px-12' : 'bg-gradient-to-r from-purple-700 to-purple-500 py-4 md:py-6 px-4 md:px-12 text-white'}`}>
                <div className="flex items-center justify-between max-w-container mx-auto">
                    <img 
                        src={`${isScrolled ? 'https://i.postimg.cc/HxxXsjxx/logo-no-text-black.png' : 'https://i.postimg.cc/g296TqS2/logo.png'}`} 
                        alt='logo' 
                        className='h-6 md:h-9'
                    />
                    <div className="flex items-center gap-4">
                        <ul className="flex items-center text-sm gap-4 md:gap-8">
                            <li className={`cursor-pointer hover:opacity-60 duration-100 font-medium ${isScrolled ? 'text-black' : 'text-white'}`}>
                                <a href="https://app.amivaplus.com/" target="_blank" rel="noreferrer">Sign In</a>
                            </li>
                        </ul>
                        <a href="https://app.amivaplus.com/register?trial=new&token=PuMG3da3LG0lD93100lkPQP" target="_blank" rel="noreferrer">
                            <p className={`text-sm px-6 py-2 border rounded-full cursor-pointer transition-all duration-300 ${isScrolled ? 'border-black text-black hover:bg-black hover:text-white' : 'border-white text-white hover:bg-white hover:text-black'}`}>
                                Free Trial
                            </p>
                        </a>
                        <p onClick={() => setShowModal(true)} className={`text-sm px-6 py-2 border rounded-full cursor-pointer transition-all duration-300 ${isScrolled ? 'border-black text-black hover:bg-black hover:text-white' : 'border-white text-white hover:bg-white hover:text-black'}`}>
                            Book a Demo
                        </p>
                        <p onClick={handleClick} className='text-sm px-6 py-2.5 bg-black rounded-full text-white hover:opacity-70 cursor-pointer'>
                            Are you a <span className='underline'>{ifBuyer ? 'broker' : 'buyer'}</span>?
                        </p>
                    </div>
                </div>
            </header>

            {/* Modal Popup */}
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    onClick={() => setShowModal(false)}
                >
                    <div className="flex flex-col bg-white h-3/4 w-2/3 rounded-lg p-4">
                        <div className="flex flex-row items-center justify-between mb-6">
                            <h2 className="text-xl font-semibold">Book a Demo</h2>
                            <p className="text-xs cursor-pointer hover:opacity-25"
                            onClick={() => setShowModal(false)}
                            >Close</p>
                        </div>

                        <iframe
                            src="https://usemotion.com/meet/mike-pantouvakis/amivaplus?d=30"
                            title="Book a Demo"
                            className="w-full h-full rounded-lg"
                        ></iframe>
                    </div>
                </div>
            )}
        </>
    );
}
